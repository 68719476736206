import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink,
  concat,
} from "@apollo/client";

const API_URL = process.env.REACT_APP_STRAPI_API_URL;
const API_TOKEN = process.env.REACT_APP_STRAPI_API_TOKEN;

const httpLink = new HttpLink({
  uri: API_URL,
  credentials: "include",
});

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: API_TOKEN || null,
    },
  }));

  return forward(operation);
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, httpLink),
});
