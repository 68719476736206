// Core
import React from "react";
import { Link } from "react-router-dom";

const Header = ({ classes }) => {
  return (
    <div className={classes.header}>
      <div className={classes.logo}>
        <Link to="/">
          <img src="images/logo/logo.svg" alt="Broqui Logo" />
        </Link>
      </div>
    </div>
  );
};

export default Header;
