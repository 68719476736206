// Styles
import classes from "./NoMatch.module.scss";

const NoMatch = () => {
  return (
    <div className={classes.content}>
      <h1>Error 404</h1>
    </div>
  );
};

export default NoMatch;
