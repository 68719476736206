import { gql } from "@apollo/client";

export const RESET_PASSWORD = gql`
  mutation resetPassword(
    $password: String!
    $passwordConfirmation: String!
    $code: String!
  ) {
    resetPassword(
      password: $password
      passwordConfirmation: $passwordConfirmation
      code: $code
    ) {
      user {
        confirmed
      }
    }
  }
`;
