// Styles
import classes from "./EmailConfirm.module.scss";

const EmailConfirmPage = () => {
  return (
    <div className={classes.content}>
      <img
        className={classes.iconThankYou}
        src="images/icon-thank-you.svg"
        alt="Thank you!"
      />
      <h1>Your registration us complete</h1>
      <p>Now you can go to our app</p>
    </div>
  );
};

export default EmailConfirmPage;
