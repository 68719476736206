/* eslint-disable react-hooks/exhaustive-deps */
// Core
import { useState, useEffect } from "react";

// Router
import { useSearchParams } from "react-router-dom";

// Apollo
import { useMutation } from "@apollo/client";
import { RESET_PASSWORD } from "../../../lib/mutations";
import { useNavigate } from "react-router-dom";

// Styles
import classes from "./ForgotPassword.module.scss";

const ForgotPasswordPage = () => {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [clientErrorMessage, setClientErrorMessage] = useState("");
  const [wasPasswordReset, setWasPasswordReset] = useState(false);
  const [isViewPass, setIsViewPass] = useState(false);
  const [isViewPassConf, setIsViewPassConf] = useState(false);

  const [resetPassword, { data, loading, error }] = useMutation(RESET_PASSWORD);

  const [searchParams] = useSearchParams();
  let navigate = useNavigate();

  useEffect(() => {
    if (searchParams.get("code") === null) navigate("/");
  }, [searchParams]);

  useEffect(() => {
    setWasPasswordReset(data?.resetPassword?.user?.confirmed);
  }, [data]);

  function handleSubmit(e) {
    e.preventDefault();

    const isValid = validate();
    if (!isValid) return;

    resetPassword({
      variables: {
        password: password,
        passwordConfirmation: passwordConfirm,
        code: searchParams.get("code"),
      },
    }).catch((error) => {
      console.error(error);
    });
  }

  const handleViewPass = (field) => {
    field === "pass"
      ? setIsViewPass(!isViewPass)
      : setIsViewPassConf(!isViewPassConf);
  };

  function validate() {
    setClientErrorMessage("");

    const isPasswordEmpty = password.length === 0;
    if (isPasswordEmpty) {
      setClientErrorMessage("The password could be empty.");
      return false;
    }

    // For PasswordValidator
    // r'^
    //   (?=.*[A-Z])       // should contain at least one upper case
    //   (?=.*[a-z])       // should contain at least one lower case
    //   (?=.*?[0-9])      // should contain at least one digit
    //   (?=.*?[!@#\\$&*~]) // should contain at least one Special character
    //   .{8,}             // Must be at least 8 characters in length
    // $

    const isPasswordRegExp =
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#\\$&*~]).{8,}$/.test(
        password
      );
    if (!isPasswordRegExp) {
      setClientErrorMessage(`
      Password:
      -should contain at least one upper case
      -should contain at least one lower case
      -should contain at least one digit
      -should contain at least one Special character
      -Must be at least 8 characters in length
      `);
      return false;
    }

    const isPasswordLessEnough = password.length !== 0 && password.length <= 30;
    if (!isPasswordLessEnough) {
      setClientErrorMessage("Password must be no more 30 characters.");
      return false;
    }

    const doPasswordsMatch = password === passwordConfirm;
    if (!doPasswordsMatch) {
      setClientErrorMessage("Passwords must match.");
      return false;
    }

    return true;
  }

  return (
    <>
      {wasPasswordReset ? (
        <div className={classes.content}>
          <h1 className={classes.titleReady}>
            You have successfully changed your password!
          </h1>
          <p className={classes.textReady}>Now you can login to your account</p>
        </div>
      ) : (
        <form method="post" onSubmit={handleSubmit} className={classes.content}>
          <h1 className={classes.title}>Set Password</h1>
          <fieldset
            className={classes.formFieldset}
            disabled={loading}
            aria-busy={loading}
          >
            <div className={classes.formInput}>
              <label htmlFor="new-password" className={classes.formLoginLabel}>
                Password
              </label>
              <p className={classes.formLoginInput}>
                <input
                  id="new-password"
                  type={isViewPass ? "text" : "password"}
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  autoComplete="off"
                />
                <div
                  className={classes.iconViewPass}
                  onClick={() => handleViewPass("pass")}
                >
                  {isViewPass ? (
                    <img src="./images/eye.svg" alt="View password" />
                  ) : (
                    <img src="./images/eye-close.svg" alt="View password" />
                  )}
                </div>
              </p>
            </div>

            <div className={classes.formInput}>
              <label
                htmlFor="password-confirm"
                className={classes.formLoginLabel}
              >
                Confirm Password
              </label>
              <p className={classes.formLoginInput}>
                <input
                  id="password-confirm"
                  type={isViewPassConf ? "text" : "password"}
                  value={passwordConfirm}
                  onChange={(event) => setPasswordConfirm(event.target.value)}
                  autoComplete="off"
                />
              </p>
              <div
                className={classes.iconViewPass}
                onClick={() => handleViewPass("confirm")}
              >
                {isViewPassConf ? (
                  <img src="./images/eye.svg" alt="View password" />
                ) : (
                  <img src="./images/eye-close.svg" alt="View password" />
                )}
              </div>
            </div>

            {clientErrorMessage ? (
              <p className={classes.errorMessage}>{clientErrorMessage}</p>
            ) : null}

            {error ? (
              <p className={classes.errorMessage}>{error.message}</p>
            ) : null}

            <button
              type="submit"
              disabled={loading}
              className={classes.submitBtn}
            >
              {loading ? "Saving..." : "Save password"}
            </button>
          </fieldset>
        </form>
      )}
    </>
  );
};

export default ForgotPasswordPage;
