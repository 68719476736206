import React from "react";

const Footer = ({ classes }) => {
  return (
    <footer>
      <p className={classes.copyright}>© 2022 Broqui, Inc.</p>
    </footer>
  );
};

export default Footer;
