// Core
import * as React from "react";

// Router
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import router from "./router/index";

import Layout from "./components/ui/Layout";
import NoMatch from "./components/ui/NoMatch/NoMatch";
import HomePage from "./components/pages/HomePage/HomePage";
import EmailConfirmPage from "./components/pages/EmailConfirmPage/EmailConfirmPage";
import ForgotPasswordPage from "./components/pages/ForgotPasswordPage/ForgotPasswordPage";

// Apollo
import { ApolloProvider } from "@apollo/client";
import { client } from "./lib/apollo/apollo-client";

function App() {
  return (
    <ApolloProvider client={client}>
      {/* <RouterProvider router={router}  /> */}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<HomePage />} />
            <Route path="/email-confirm" element={<EmailConfirmPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="*" element={<NoMatch />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
