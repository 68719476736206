/* eslint-disable jsx-a11y/anchor-has-content */

// Styles
import classes from "./Home.module.scss";

const HomePage = () => {
  return (
    <div className={classes.content}>
      <h1>Find out healthy food</h1>
      <p className={classes.description}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Malesuada leo
        amet facilisis eget lectus.
      </p>

      <div className={classes.joinBox}>
        <p className={classes.info}>Join with</p>
        <div className={classes.btnGroup}>
          <a
            className={classes.btnGooglePlay}
            href="https://play.google.com/"
            target="_blank"
            rel="noreferrer"
          ></a>
          <a
            className={classes.btnAppStore}
            href="https://www.apple.com/ru/app-store/"
            target="_blank"
            rel="noreferrer"
          ></a>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
