// Core
import { Outlet } from "react-router-dom";
// import { Link } from "react-router-dom";

// Components
import Header from "../Header";
import Footer from "../Footer";

// Styles
import classes from "./Layout.module.scss";

const Layout = () => {
  return (
    <div className={classes.content}>
      <header>
        <Header classes={classes} />
      </header>
      <main className={classes.main}>
        <Outlet />
      </main>

      <Footer classes={classes} />
    </div>
  );
};

export default Layout;
